import {withTranslation} from '@wix/yoshi-flow-editor'
import {isEditor, isMobile} from '../../../../../commons/selectors/environment'
import {
  getAlignment,
  getCompactHorizontalDividerHeight,
  getCompactHorizontalDividerWidth,
  getContentAlignment,
  getImagePosition,
  getImageWidth,
  getVerticalImagePosition,
  getWidgetLayout,
  isDateEnabled,
  isHorizontalLayout,
  isLocationEnabled,
  isVerticalLayout,
  isCompactDividerVisible,
  isImageVisible,
  isOnlyButtonVisible,
  isOnImageLayout,
  isRibbonVisible,
  getComponentConfig,
  getOneButtonWidth,
} from '../../selectors/settings'
import {getFirstEvent} from '../../selectors/events'
import {resizeToDefault} from '../../services/resize'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {SingleEventOwnProps, SingleEventStateProps} from './interfaces'
import {SingleEvent as SingleEventPresentation} from './single-event'

const mapState = ({
  state,
  host: {dimensions, resizeComponent},
  isRTL,
  actions: {resized},
  sdk,
}: AppProps): SingleEventStateProps => {
  const componentConfig = getComponentConfig(state)
  const event = getFirstEvent(state)

  return {
    event,
    layout: getWidgetLayout(componentConfig),
    alignment: getAlignment(componentConfig),
    verticalAlignment: getContentAlignment(componentConfig),
    showDate: isDateEnabled(componentConfig),
    showLocation: isLocationEnabled(componentConfig),
    compactDividerVisible: isCompactDividerVisible(state),
    compactDividerWidth: getCompactHorizontalDividerWidth(componentConfig),
    compactDividerHeight: getCompactHorizontalDividerHeight(componentConfig),
    buttonLayout: isOnlyButtonVisible(state),
    buttonWidth: getOneButtonWidth(state.component),
    onImageLayout: isOnImageLayout(state),
    horizontalLayout: isHorizontalLayout(componentConfig),
    verticalLayout: isVerticalLayout(componentConfig),
    imageVisible: isImageVisible(state),
    imageWidth: getImageWidth(componentConfig),
    imagePosition: getImagePosition(componentConfig),
    verticalImagePosition: getVerticalImagePosition(componentConfig),
    mobile: isMobile(state),
    editor: isEditor(state),
    height: dimensions.height,
    width: dimensions.width,
    rtl: isRTL,
    resizeToDefault: layout => {
      if (resizeComponent) {
        resizeToDefault(resizeComponent, state, layout)
      }
    },
    showRibbon: isRibbonVisible(state, event),
    needsResize: state.needsResize,
    resized,
  }
}

export const SingleEvent = connect<SingleEventOwnProps, SingleEventStateProps>(mapState)(
  withTranslation()(SingleEventPresentation),
)
export * from './interfaces'
